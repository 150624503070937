// import Book from '../pages/Book';
import Tim from '../pages/Tim';

const routes = [
   
    {
        path: "/credits",
        component: Tim,
    },
    {
        path: "/",
        component: Tim,
    },
]

export default routes;