/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /admin

*/

import React, { Component } from "react";
import {  Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";


class Auth extends Component {

    state = {
        shouldRedirect: false
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {

            return (
                <Route
                    path={`${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };


    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        return (

            <div className="main-conten" ref="mainContent">
                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/" />
                </Switch>
            </div>

        );
    }
}

export default Auth