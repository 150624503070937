
import apiWorker from '../apiWorker'

const nylas = {

    getCalendar: (times)  => apiWorker('post',  `/v1/integrations/nylas/calendar`, times),
    createEvent: (data)  => apiWorker('post',  `/v1/integrations/nylas/events`, data),
    getConsultation: (consultation_id)  => apiWorker('get',  `/v1/integrations/nylas/events/${consultation_id}`),
    finishConsultation: (consultation_id, data)  => apiWorker('patch',  `/v1/integrations/nylas/events/${consultation_id}`, data),
    
    getLead: (lead_id)  => apiWorker('get',  `/v1/integrations/nylas/leads/${lead_id}`),
    createLead: (data)  => apiWorker('post',  `/v1/integrations/nylas/leads`, data),

}

export default nylas;