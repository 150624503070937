import { Container } from 'reactstrap';

import Logo from 'assets/img/brand/BizHeadLogoCut.png';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import { useCallback, useEffect } from 'react';

import img1 from './images/img1.jpg';
import img2 from './images/img2.jpg';
import img3 from './images/img3.jpg';
import img4 from './images/img4.jpg';
import img5 from './images/img5.jpg';
import img6 from './images/img6.jpg';
import LogoFlipped from './images/LogoFlipped.jpg';

import Contact from './Contact'

const Tim = () => {

    const scrollTo = (id) => {
        const el = document.getElementById(id)
        el.scrollIntoView()
    }

    const fireEvent = useCallback(() => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11094234320/x4LRCM3l9fgDENCpkqop',
            'event_callback': (x) => {
                console.log(x)
            }
        });
    }, [])

    useEffect(() => {
        const numbers = document.querySelectorAll('.tel')

        for (let i = 0; i < numbers.length; i++) {
            const element = numbers[i];
            element.addEventListener('click', fireEvent)
        }
    }, [fireEvent])

    return (
        <div className=''>

            <div className='border-botto bg-white mb--6 mb-md-0'>
                <Container className="py-3 ">
                    <Row>
                        <Col lg={4} md={3} xs={8} className="align-self-center">
                            <img src={LogoFlipped} className="w-100" alt="..."></img>
                        </Col>
                        <Col lg={8} md={9} className="align-self-center text-right d-none d-md-block">
                            <ul className='mb-0 pb-0 ml-0 pl-0'>
                                <li className='list-style-none d-inline px-4 text-dark cursor-pointer' onClick={() => scrollTo('archk-erc')}>What is ERC?</li>
                                <li className='list-style-none d-inline px-4 text-dark cursor-pointer' onClick={() => scrollTo('archk-testimonials')}>Testimonials</li>
                                <li className='list-style-none d-inline px-4 text-dark cursor-pointer' onClick={() => scrollTo('archk-works')}>How It Works</li>
                                <li className='list-style-none d-lg-inline px-4 text-dark cursor-pointer d-none' onClick={() => scrollTo('archk-faq')}>FAQ</li>
                                <li className='list-style-none d-inline px-4 text-dark cursor-pointer'>
                                    <button onClick={() => scrollTo('archk-book')} className='btn btn-info btn-l py-3'>Apply Now</button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="bg-white py-7" id="archk-header">
                <Container>
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <p className="mb-0 text-dark">With a Qualified Tax Attorney</p>
                            <h2 className='display-1 text-dark mb-5'>Get Up to <b className='text-success'>$26,000 per Employee</b> with ERC Credits</h2>
                            <button className='btn btn-success btn-lg mb-6 mb-md-0' onClick={() => scrollTo('archk-book')}>Check My Eligibility</button>
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <img src={img1} className="w-100" alt="..." />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="py-7" style={{background: '#f5f8f6'}} id="archk-erc">
                <Container>
                    <Row>
                        <Col lg={6} className="align-self-center">
                            <p className="mb-0 text-dark">THE EMPLOYEE RETENTION CREDIT</p>
                            <h2 className='display-1 text-dark mb-'><b className='text-success'><i className="fas fa-arrow-right " /></b> What is the ERC?</h2>
                            <p className="mb-0 text-dark font-weight-bol text-lg">The Employee Retention Tax Credit (ERC) is a payroll tax refund from the United States Treasury Department applicable to businesses who kept employees on payroll during the pandemic.</p>
                            <div className='pt-6 mb-6 mb-lg-0'>
                                <button className='btn btn-info btn-lg' onClick={() => scrollTo('archk-book')}>Learn More</button>
                                <a href="https://book.mybizheadlaw.com?l=63e19189933efc387e0bbbe9" className='btn btn-outline-info btn-lg' >Request Consultation</a>
                            </div>
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <video poster="https://www.bizheadlaw.com/wp-content/uploads/2022/06/Screenshot_8.png" controls="controls" width="100%" height="100%" >
                                <source src="https://www.bizheadlaw.com/wp-content/uploads/2022/06/ertc_explainer_-_biz_head_law720p-3.mp4" type="video/mp4" />
                                <source src="https://www.bizheadlaw.com/wp-content/uploads/2022/06/ertc_explainer_-_biz_head_law720p-3.mp4" type="video/ogg" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="py-7" style={{background: '#f5f8f6'}} id="archk-qualify">
                <Container>
                    <Row>
                    <Col lg={6} className="align-self-center">
                            <img src={img2} className="w-100" alt="..." />
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <h2 className='display-3 text-dark mb-5'><b className=''>Does My Business Qualify?</b></h2>
                            <p className='text-dark'>If your business experienced ANY of the following as a result of COVID, you likely qualify for a tas refund:</p>

                            <Row>
                                <Col lg={6}>
                                    <div className='mb-4 text-dark'><i className="fas fa-check-circle text-success mr-2 " /> Full or Partial Shutdown</div>
                                    <div className='mb-4 text-dark'><i className="fas fa-check-circle text-success mr-2 " /> Supply Chain Challenges</div>
                                    <div className='mb-4 text-dark'><i className="fas fa-check-circle text-success mr-2 " /> Reduction in Goods or Services</div>
                                </Col>
                                <Col lg={6}>
                                    <div className='mb-4 text-dark'><i className="fas fa-check-circle text-success mr-2 " /> Limited Capacity</div>
                                    <div className='mb-4 text-dark'><i className="fas fa-check-circle text-success mr-2 " /> Reduction in Revenue</div>
                                    <div className='mb-4 text-dark'><i className="fas fa-check-circle text-success mr-2 " /> New Business Startup</div>
                                </Col>
                            </Row>

                            <div className="mt-5">
                                <button className='btn btn-success btn-lg' onClick={() => scrollTo('archk-book')}>Check My Eligibility</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <div className="bg-white py-3">
                <Container>
                    <p className='text-dark lead'>YOUR BUSINESS IS IN GOOD COMPANY:</p>
                    <h2 className='display-2 text-dark mb-5'><b className='text-success'><i className="fas fa-arrow-right " /></b> We've brought over <b className='text-success'>$100 Million dollars</b> in IRS tax credits to businesses just like yours</h2>
                </Container>
            </div>

            <div className="py-7 text-center" style={{background: '#f5f8f6'}} id="archk-works">
                <Container>
                    <h2 className='display-2 text-dark mb-5'>How Does It Work?</h2>
                    <Row>
                        <Col lg={4} className="align-self-center">
                            <Card>
                                <CardBody className="bg-white">
                                    <img src={img4} className="mt-3" style={{width: 100}} alt="..." />
                                    <p className='text-dark font-weight-bold mt-3'>Schedule Free Qualification call and check eligibility</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} className="align-self-center">
                            <Card>
                                <CardBody className="bg-white">
                                    <img src={img5} className="mt-3" style={{width: 100}} alt="..." />
                                    <p className='text-dark font-weight-bold mt-3'>Work with our team to get docs and info filed</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4} className="align-self-center">
                            <Card>
                                <CardBody className="bg-white">
                                    <img src={img6} className="mt-3" style={{width: 100}} alt="..." />
                                    <p className='text-dark font-weight-bold mt-3'>Receive funds that you don't have to pay back</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <div className="text-center mt-5">
                        <button className='btn btn-success btn-lg' onClick={() => scrollTo('archk-book')}>Get Qualified Now!</button>
                    </div>

                </Container>
            </div>

             
            <div className=" py-7" id="archk-testimonials">
                <Container>
                    <h2 className='display-2 text-center mb-4'>What Our Clients Say <i className="fas fa-users " /></h2>
                    <Row>
                        <Col lg={4}>
                            <Card lg={3} className="bg-white">
                                <CardHeader className="bg-white">
                                    <CardTitle className="mb-0"><i className="fas fa-quote-left text-warning " /></CardTitle>
                                </CardHeader>
                                <CardBody className="bg-secondary">
                                    <p className="text-dark mb-0"> The tax attorneys at Biz Head Law’s guided us and answered all of our questions we had. Because of their complete knowledge of the Employee Retention Tax Credit, and their thorough approach to building our analysis, we felt secure filing the numbers they provided.</p>
                                </CardBody>
                                <CardFooter className="bg-white">
                                    <p className='mb-0 font-weight-bold text-darker'>JANITORIAL SERVICE, OHIO</p>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card lg={3} className="bg-white">
                                <CardHeader className="bg-white">
                                    <CardTitle className="mb-0"><i className="fas fa-quote-left text-warning " /></CardTitle>
                                </CardHeader>
                                <CardBody className="bg-secondary">
                                    <p className="text-dark mb-0">I feel even more confident referring my clients to Biz Head Law after completing the process of submitting my documentation to qualify for the ERC. Our accountant never even told us that we were eligible. Biz Head Law educated us and walked us through the entire process. We’re extremely pleased with our long-term relationship with the law firm and look forward to many more years of working with them.</p>
                                </CardBody>
                                <CardFooter className="bg-white">
                                    <p className='mb-0 font-weight-bold text-darker'>LAW FIRM, TEXAS</p>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card lg={3} className="bg-white">
                                <CardHeader className="bg-white">
                                    <CardTitle className="mb-0"><i className="fas fa-quote-left text-warning " /></CardTitle>
                                </CardHeader>
                                <CardBody className="bg-secondary">
                                    <p className="text-dark mb-0"> Not only were we able to benefit financially from the payroll tax credits that Biz Head Law delivered; they have also inspired us to stay current on other types of tax credits to which we may be entitled. We never realized about government incentives that can support the health of our business.</p>
                                </CardBody>
                                <CardFooter className="bg-white">
                                    <p className='mb-0 font-weight-bold text-darker'>SOFTWARE COMPANY, ILLINOIS</p>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="py-7" style={{background: '#f5f8f6'}}>
                <Container>
                    <Row>
                    <Col lg={6} className="align-self-center">
                            <img src={img3} className="w-100" alt="..." />
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <h2 className='display-3 text-dark mb-5'><b className=''>Book Your ERTC Consultation</b></h2>
                            <p className='text-dark text-italic lead font-weight-bold'>Don't wait, talk to one of our ERTC trained specialists before time runs out to receive the credit.</p>
                            <p className='text-dark'>We will never sell your information or send you spam. After you enter your information you will receive a call shortly from a real person to see if you qualify.</p>

                            <button className='btn btn-success btn-lg' onClick={() => scrollTo('archk-book')}>Get Started</button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="py-5 bg-white">
                <Container>
                    <p className='text-muted font-weight-bold mb-0'>Talk to an advisor</p>
                    <h2 className="text-dark mb-0 display-2"><i className="fas fa-arrow-right text-success " /> Check Your Eligibility <a className="text-success tel" href="tel:8555011251">(855) 501-1251</a></h2>

                </Container>
            </div>

            <div className='bg-secondary border-top py-7' id="archk-faq">
                <Container>
                    <h2 className="text-dark display-3 mb-5">Frequently Asked Questions</h2>

                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. How much will I pay in legal fees to receive my Tax Credit?</p>
                    <p className="text-dark">Nothing, unless you receive a check from the government. Getting your Employee Retention Tax Credit is entirely free unless you receive the credit; no credit card or bank information is required and no upfront payment. This credit comes directly from the federal government in the form of a check mailed directly to you. We work strictly on a contingency fee meaning we don't get paid until you do and we only receive a small part of the credits for our legal and services provided.</p>
                 
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Does the Employee Retention Credit have to be paid back?</p>
                    <p className="text-dark">No. The Employee Retention Credit is a fully refundable tax credit that eligible  employers claim against certain employment taxes. It is not a loan and does not have to  be paid back. For most taxpayers, the refundable credit is in excess of the payroll taxes  paid in a credit-generating period.</p>

                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Will I speak with a real Attorney?</p>
                    <p className="text-dark">Yes! The first step in the process is to speak with one of our attorneys either over the phone or on a video conference to discuss your tax credits and answer any questions you have.</p>
                  
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. How much work is involved in the process?</p>
                    <p className="text-dark">Very little. Our streamlined processes allow you to complete the process in under with relatively little time spent. Gather and upload a few document like your your payroll tax returns and payroll records and other supporting documentation, fill out an online questionnaire, and sign your amended returns once we prepare them. We take on all the work so you don't have to.</p>
             
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q.Can I get both the ERC and PPP Loan?</p>
                    <p className="text-dark">Yes. While an employer may not include wages funded by a PPP loan in the ERC  calculation, PPP funds only apply to eight to ten weeks of wage expenses. The ERC  eligibility periods are longer. PPP loans can also fund non-wage expenses. For ERC purposes, it is most important to develop work papers that allocate the PPP  funding across the entire 24 week Covered Period. PPP funding may be allocated to wages that would not generate any ERC (e.g., to  owners of the company or to wages in excess of $10,000 in one of the four ERC credit generating periods). </p>
                  
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Is there a timeline on when I can receive my ERTC?</p>
                    <p className="text-dark">Technically, a business has 3 years from the applicable payroll period to apply for the credit. However, Congress could end the program at any time. If you believe you may qualify, do not delay.</p>
                   
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. How do I know if I'm eligible to receive the Employee Retention Tax Credit?</p>
                    <p className="text-dark">Almost every business that had 1 or more employees during 2020 or 2021 is eligible, our attorneys can discuss your exact situation with a free consultation.</p>
           
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. How does the Employee Retention Credit work? </p>
                    <p className="text-dark">The ERC is a refundable payroll tax credit that can be as high as $5,000 per employee in 2020 and as high as $21,000 per employee in 2021. </p>
                
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Will I get audited? </p>
                    <p className="text-dark">Our lawyers can control the quality of our work, but we cannot control the behavior of the IRS. While the chances of an audit are extremely remote, if a business advances a good faith position that it is entitled to the credit, an audit should not be of meaningful concern. Our attorneys will advise you regarding what information a business needs to gather to qualify for the credit</p>
                
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. What are ERC qualified wages?</p>
                    <p className="text-dark">ERC credits are calculated based on the qualifying wages paid to employees during  eligible employer status. For most companies taking advantage of this program, the  refundable tax credits are well in excess of the payroll taxes paid by the employers.  ERC benefits can be larger than the amounts a company received in PPP funding.</p>
                  
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Are benefits of the Employee Retention Credit the same for large and small  employers? </p>
                    <p className="text-dark">Small employers receive enhanced benefits under the ERC regime. Specifically, for the  time they are an Eligible Employer, they can include wages paid to all  employees. Large employers can only include wages paid to employees for not  providing services.</p>

                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Is the Employee Retention Credit only for full-time employees?</p>
                    <p className="text-dark">No. An employer may include wages paid to part-time and full-time employees in the  calculation of the ERC. The only limitation on the calculation of the credits is that an  employer may only calculate the credits on the first $10,000 of wages and health plan  costs paid to each employee during each credit-generating period.</p>

                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. If I use a PEO instead of a traditional payroll tax provider, can I still claim the  ERC?</p>
                    <p className="text-dark">Yes. Employers using a PEO are still entitled to claim the Employee Retention Credit.</p>


                </Container>
            </div>

            <div id="archk-book">
                <Contact />
            </div>

            <div className=' border-top mt-9'>
                <Container className='pt-4 text-center'>
                    <img src={Logo} className="mr-3 mr-md-6" alt="biz head law" style={{width: 200}} />
                    <small>&copy; 2023 Biz Head Law <span className='d-none d-md-inline-block'> | All Rights Reserved</span></small>
                </Container>
            </div>

        </div>
    )
}

export default Tim;