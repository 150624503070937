import { useState } from 'react';
import { Container, FormGroup, Input, FormFeedback, Row, Col } from 'reactstrap';
import Logo from 'assets/img/brand/BizHeadLogoCut.png'

import _nylas from '_functions/nylas'

import { toggleAlertBS } from 'store/functions/system/system';

import { getUrlParameter } from 'utils/urls';
import { toggleStandardLoader } from 'store/functions/system/system';

const Book = () => {

    const [givenName, setGivenName] = useState(getUrlParameter('contact_first_name') || '');
    const [familyName, setFamilyName] = useState(getUrlParameter('contact_last_name') || '');
    const [companyName, setCompanyName] = useState(getUrlParameter('name') || '');
    const [companySize, setCompanySize] = useState('');
    const [email, setEmail] = useState(getUrlParameter('intake_email') || '');
    const [phone, setPhone] = useState(getUrlParameter('contact_phone_number') || '');
    const [errs, setErrs] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = async () => {
        let _errs = [];

        if(!givenName) _errs.push('givenName');
        if(!familyName) _errs.push('familyName');
        if(!companyName) _errs.push('companyName');
        if(!companySize || companySize === 'false') _errs.push('companySize');
        if(!email) _errs.push('email');
        if(!phone) _errs.push('phone');

        if(_errs.length) return setErrs(_errs);

        toggleStandardLoader(true);
        const created = await _nylas.createLead({
            givenName,
            familyName,
            companyName,
            companySize,
            email,
            phone,
            lead_source: '63e19189933efc387e0bbbe9',
        })
        toggleStandardLoader(false);

        if(created.success ||( created.message && created.message[0] === 'Duplicate Lead')) {
            setSubmitted(true)
            window.gtag('event', 'conversion', {
                'send_to': 'AW-11094234320/WBcPCIDLkYwYENCpkqop',
                'value': 100.0,
                'currency': 'USD'
            });
        } else {
            if(created.message=== 'lead_source')  return toggleAlertBS('info', 'The page you have been given is incorrect, to reach out to us email us a operations@bizheadlaw.com')
            if(created.errs && created.errs.length) return setErrs(created.errs)

            toggleAlertBS('info', "Please Try Again.")
        
        }


    }

    if(submitted) return (
        <Container className="pt-5" >
        
            <div style={{maxWidth: 360, margin: 'auto', }} className="z-depth-5 border rounded pb-3">
                <div className="text-centr pl-3 pt-3 ml-1">
                    <img src={Logo} style={{width: 225}} alt="Biz Head Law" />
                </div>

                <div className="archk-calendar-booking">

                    <div className="archk-calendar-wrapper">

                        <div className="archk-calendar-header pb-0">
                            <h2 className="text-primary mb-0 display-3"><i className="fas fa-check text-white mr-2 " /> All Set!</h2>
                        </div>
                        <div className="archk-calendar-header pb-0">
                            <p className="text-sm ">A representative will reach out to your shortly, we look forward to speaking with you.</p>
                        </div>

                    </div>

                </div>
            </div>
        </Container>
    )

    return (
       <Container className="pt-8" style={{width: 800}}>
        
            <h2 className='display-2'>See How Much You're Qualified For:</h2>

            <div>

                <div className="arch-calendar-booking">

                    <div className="arch-calendar-wrapper">

                
                        <div className="arch-calendar-body">
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label className="form-control-label">First Name*</label>
                                        <Input 
                                            type="text"
                                            value={givenName}
                                            onChange={(e) => setGivenName(e.target.value)}
                                            invalid={errs.includes('givenName')}
                                            valid={!errs.includes('givenName') && givenName}
                                        />
                                        <FormFeedback>Please Enter Your First Name.</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label className="form-control-label">Last Name*</label>
                                        <Input 
                                            type="text"
                                            value={familyName}
                                            onChange={(e) => setFamilyName(e.target.value)}
                                            invalid={errs.includes('familyName')}
                                            valid={!errs.includes('familyName') && familyName}
                                        />
                                        <FormFeedback>Please Enter Your Last Name.</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <label className="form-control-label">Company Name*</label>
                                <Input 
                                    type="text"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    invalid={errs.includes('companyName')}
                                    valid={!errs.includes('companyName') && companyName}
                                />
                                <FormFeedback>Please Enter A Valid Email Address.</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">How Many People Are In Your Company?*</label>
                                <Input 
                                    type="select"
                                    value={companySize}
                                    onChange={(e) => setCompanySize(e.target.value)}
                                    invalid={errs.includes('companySize')}
                                    valid={!errs.includes('companySize') && companySize}
                                >
                                    <option value="false">Click To Select</option>
                                    <option value="1 - 10">1 - 10</option>
                                    <option value="11 - 20">11 - 20</option>
                                    <option value="21 - 30">21 - 30</option>
                                    <option value="31 - 50">31 - 50</option>
                                    <option value="51 - 100">51 - 100</option>
                                    <option value="100+">100+</option>
                                </Input>
                                <FormFeedback>Please Select How Many People Are In Your Company.</FormFeedback>
                            </FormGroup>
                            
                            <FormGroup>
                                <label className="form-control-label">Email*</label>
                                <Input 
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    invalid={errs.includes('email')}
                                    valid={!errs.includes('email') && email}
                                />
                                <FormFeedback>Please Enter Your Email.</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">Phone*</label>
                                <Input 
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    invalid={errs.includes('phone')}
                                    valid={!errs.includes('phone') && phone}
                                />
                                <FormFeedback>Please Enter A Valid Phone Number.</FormFeedback>
                            </FormGroup>

                        </div>

                        <div className="archk-calendar-body mt-5">
                            <button 
                                onClick={onSubmit} 
                                className="btn btn-primary btn-block btn-lg"
                            >
                                <i className="fas fa-check-double mr-2 " /> Check Your Eligibility
                            </button>

                            <p className="text-sm mb-0 mt-4"><small>**By entering your info above, you authorize Biz Head Law LLC to send you text message and email notifications regarding your potential ERTC case or for your scheduled appointment(s). <b className="text-underline text-white"> We will never sell your information and you can opt out at any time with just a few clicks.</b></small></p>
                        </div>
                    </div>

                </div>
            </div>

       </Container>
    )
}

export default Book;